import React, { Component, useState, useRef } from "react";
import QRCode from "react-qr-code";
import { Link, NavLink } from "react-router-dom";
import * as htmlToImage from "html-to-image";
import { getAccount } from '@wagmi/core'
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import {
  BsFillPersonFill,
  BsFillGridFill,
  BsQrCode,
  BsFillStarFill,
  BsFillHouseFill,
} from "react-icons/bs";
import { RiSettings5Fill } from "react-icons/ri";
import { IconContext } from "react-icons";
import "bootstrap/dist/css/bootstrap.css";

const DynamicQrGen = () => {

  const account = getAccount()

  return (
    <Container>
      <Row className="justify-content-center align-items-center" style= {{minHeight: '100vh'}} >
        <Col xs={12} md={6}>
        <div className="d-flex justify-content-center">
          <QRCode
              size={300}
              style={{ height: "auto", maxWidth: "100%", width: "50%" }}
              value={account.address}
              />
        </div>
        </Col>
      </Row>
      <Navbar bg="primary" className="fixed-bottom">
            <Nav className="w-100 justify-content-around">
                <Nav.Link>{<BsFillGridFill />}</Nav.Link>
                <Nav.Item>
                  <NavLink  to="qrgen" className="nav-link"><BsFillPersonFill /> </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/qrscan"
                        state={{ fps: 10, qrbox:250, disableFlip:false, qrCodeSuccessCallback:serializedCallback }} >
                      {<HomeTabIcon />}
                      <IconContext.Provider
                        value={{ size: "2rem", className: "services-icon" }}
                      >
                        <div className="fab">
                          <BsQrCode />
                        </div>
                      </IconContext.Provider>
                    </Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>{<BsFillStarFill />}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>{<RiSettings5Fill />}</Nav.Link>
                </Nav.Item>
            </Nav>
          </Navbar>
    </Container>
   
  );

};

const onNewScanResult = (decodedText, decodedResult) => {
  console.log(decodedText);
  const data = { popup: true };
  if(localStorage.getItem('dataKey') === null) {
      localStorage.setItem('dataKey', JSON.stringify(data));
  } 
  window.history.go(-1);
};

const serializedCallback = onNewScanResult.toString();

const HomeTabIcon = () => {
  return (
    <div className="home-tab-icon">
      <BsFillHouseFill />
    </div>
  );
};

export default DynamicQrGen;