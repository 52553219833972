import { Html5QrcodeScanner } from 'html5-qrcode';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";


const qrcodeRegionId = "html5qr-code-full-region";



// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
    let config = {};
    if (props.fps) {
        config.fps = props.fps;
    }
    if (props.qrbox) {
        config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
    }
    return config;
};

const QrScan = (props) => {
    const data = useLocation();
    useEffect(() => {
        props = data.state;
        // when component mounts
        const config = createConfig(props);
        const verbose = props.verbose === true;
        // Suceess callback is required.
        props.qrCodeSuccessCallback = new Function('return ' + props.qrCodeSuccessCallback)();
        // if (!(props.qrCodeSuccessCallback)) {
        //     throw "qrCodeSuccessCallback is required callback.";
        // }
        const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
        html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);

        // cleanup function when component will unmount
        return () => {
            html5QrcodeScanner.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        };
    }, [data]);

    return (
        <div id={qrcodeRegionId} />
    );
};

export default QrScan;