import "./styles.css";
import React, { useState } from "react";
import Home from "./screens/Home";
import QrScan from "./screens/QrScan";
import DynamicQrGen from "./screens/DynamicQrGen";
import { Route, Routes, useNavigate } from "react-router-dom";


const App = () => {


  return (
      <>
       <Routes>
          <Route path="/qrgen" Component={DynamicQrGen} />
          <Route path="/qrscan" Component={QrScan} />
          <Route path="/qrpay" />
          <Route path="/" Component={Home} />
        </Routes>
      </>
  );
};

export default App;