import React, { Component, useState } from "react";
import { WalletConnector } from "../components/WalletConnector";
import { useNavigate } from "react-router-dom";



const Home = () => {
    return (
      <>
        <WalletConnector />
        <style jsx global>{`
          @import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@300;700&display=swap");

  
          main {
            padding: 5rem 0;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
  
          main p {
            font-size: 1.2rem;
          }

          .logo {
            margin-bottom: 2rem;
          }
  
          .logo img {
            width: 100%;
          }
  
          @media (max-width: 600px) {
            .grid {
              width: 100%;
              flex-direction: column;
            }
          }
        `}</style>
      </>

    );
  }

  export default Home;