import React, { useState , useEffect } from 'react';
import { ethers } from 'ethers';
import SdcTestNet from '../abi/SdcTestNet';


const useContractExecutor = (contractAddress) => {
  const [provider, setProvider] = useState(null);
  const [contract, setContract] = useState(null);

  useEffect(() => {
    console.log(contractAddress);
    if (window.ethereum) {
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(newProvider);
      setContract(new ethers.Contract(contractAddress, SdcTestNet, newProvider.getSigner()));
    }
  }, [contractAddress]);

  const handleExecute = async (recipient, amount) => {
    if (!contract) {
      throw new Error('Contract not initialized');
    }

    // try {
    //   const tx = await contract.transferToRecipient(recipient, amount);
    //   await tx.wait(); // Wait for the transaction to be mined
    //   return 'Transaction successful';
    // } catch (error) {
    //   throw new Error('Error executing contract function:', error);
    // }
    const tx = await contract.transfer(recipient, amount);
    await tx.wait(); // Wait for the transaction to be mined
    return 'Transaction successful';
  };

  return { handleExecute };
};

export default useContractExecutor;
