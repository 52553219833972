import React, { Component, useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import "../styles.css";
import {
  BsFillPersonFill,
  BsFillGridFill,
  BsQrCode,
  BsFillStarFill,
  BsFillHouseFill,
  BsHouse
} from "react-icons/bs";
import { ethers } from 'ethers';
import CryptoJS from 'crypto-js';
import { RiSettings5Fill } from "react-icons/ri";
import { IconContext } from "react-icons";
import "bootstrap/dist/css/bootstrap.css";
import useContractExecutor from "../functional/ContractExecutor";
import { Form, Button, Modal, Navbar, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';



const Dashboard = () => {

    const [showModal, setShowModal] = useState(false);

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [showReceiptModal, setShowReceiptModal] = useState(false);

    const [amount, setAmount] = useState(0);

    const [account, setAccount] = useState({});

    const [cryptoStatus, setCryptoStatus] = useState(0);

    const [cryptoHash, setCryptoHash] = useState('');

    const [receiptUrl, setReceiptUrl] = useState('');

    const [fpxStatus, setFpxStatus] = useState(0);

    const amountRef = useRef();

    const refRef = useRef();

    const emailRef = useRef();

    const fpxDataRef = useRef();

    const contractAddress = '0xb7272d04f1194ADAe4A342ccC1E09d3a53C19Bf8';

    const { handleExecute } =  useContractExecutor(contractAddress);

    const receiverWallet = '0x7d319dBdD9E2209B1abf51755d6559824C7e424f';

    //const receiverWallet = '0x12d42f1b17ac0bac9811acfa2b250a31a7b9acc3';

    const sendMatic =  async ()  =>  {
        const rate = 1 / 3.52;
        const fpx_fee = 1;
        const sdc_fee = 0.05;
        const amount_matic = (parseFloat(localStorage.getItem('amount')) * rate);
        const total_fee = (amount_matic + fpx_fee) * sdc_fee;
        const total_amount = total_fee + fpx_fee + amount_matic;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const transaction = {
          to: receiverWallet,
          value: ethers.utils.parseUnits(total_amount.toString(), 'ether')
        };
    
        const tx = await signer.sendTransaction(transaction);
        localStorage.removeItem('amount');
        let receipt = tx.wait();
        receipt.then(response => {
            setCryptoStatus(response.status);
            setCryptoHash(response.transactionHash);
            
        })
        .catch(error =>  { 
          console.error(error);
          setCryptoStatus('');
          setCryptoHash('');
        });
        
        
    };

    const executeTransaction = async () => {
      try {
        const myr = (localStorage.getItem('amount') / 6).toString();
        const wei = ethers.utils.parseUnits(myr, 'ether').toString();
        const result = await handleExecute('0x12d42f1b17ac0bac9811acfa2b250a31a7b9acc3', wei); // Replace recipient and amount
        console.log(result);
      } catch (error) {
        console.error(error);
      }
      
    };

    const handleModalOpen = () => {
      localStorage.removeItem('dataKey');
      setShowModal(true);
    };

    const handleModalClose = () => {
      setShowModal(false);
    };

    const handleSuccessModalClose = () => {
      setShowSuccessModal(false);
    };

    const handleReceiptModalClose = () => {
      setShowReceiptModal(false);
    };

    const handleShowReceipt = () => {
      setShowSuccessModal(false);
      setShowReceiptModal(true);
    };

    const handleSaveChanges = async (e) => {
      e.preventDefault();
      const accInfo = JSON.parse(localStorage.getItem('accountInfo'));
      if(accInfo != null) {
        accInfo.amount = amountRef?.text;
        localStorage.setItem('amount',amountRef?.text);
        setShowModal(false);
        await sendMatic();
      }
    };

    const invokeChipSend = () => {
      const api_key = '83314ba9-be4c-4d26-8d65-4a33d5ac3d00';
      const prod_api_key = '19d29afe-b49f-4300-97ad-d69fd0f9f629';
      const api_secret = 'b0c944a4-c70d-4d51-b4e7-ea5ac5d5339e';
      const prod_api_secret = '5b9f5c6b-1f23-4ccc-afbf-9e665eaf8752';
      const epoch = (new Date).getTime();
      const epochSecs = Math.floor( epoch / 1000 );
      const data = epochSecs.toString() + prod_api_key
      const hmacDigest = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA512(data, prod_api_secret));

      const options = {
        method: 'POST',
        headers: {
          epoch: epochSecs,
          checksum: hmacDigest,
          Authorization: `Bearer ${prod_api_key}`,
          'Content-Type': 'application/json'
        },
        body: fpxDataRef?.text
      };

      console.log(fpxDataRef?.text);

      fetch('https://api.chip-in.asia/api/send/send_instructions', options)
        .then(response => response.json())
        .then(response =>  {
          console.log(response);
          setReceiptUrl(response.receipt_url);
          setShowSuccessModal(true);
          localStorage.removeItem('accountInfo');
          fetch('https://dapp.sdc.cx/api/cash_transactions', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(response)
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            console.log('Success:', data);
            // Handle success response here
          })
          .catch(error => {
            console.error('Error:', error);
            // Handle error here
          });
        })
        .catch(err => console.error(err));

    }


    useEffect(() => {
      if(localStorage.getItem('dataKey') !== null) {
        handleModalOpen();
      }
      if(cryptoStatus != '' && cryptoHash != '') {
        const fpxData = {
          amount: parseFloat(amountRef?.text),
          bank_account_id: JSON.parse(localStorage.getItem('accountInfo')).bank_account_id,
          description: cryptoHash,
          email: 'muzzammil@struktu.re',
          reference: uuidv4()
        };
        fpxDataRef.text = JSON.stringify(fpxData);
        console.log(fpxData?.text);
        invokeChipSend();

      }

    },[cryptoStatus, cryptoHash]);

    const onNewScanResult = (decodedText, decodedResult) => {
      const data = { popup: true };
      const accountInfo = JSON.parse(decodedText);
      if(localStorage.getItem('dataKey') === null) {
          localStorage.setItem('dataKey', JSON.stringify(data));
      }
      if(localStorage.getItem('accountInfo') === null) {
        localStorage.setItem('accountInfo', JSON.stringify(accountInfo));
      }
      window.history.go(-1);
    };

    const onNewScanResult2 = (decodedText, decodedResult) => {
      console.log(decodedText);
      const data = { popup: true };
      if(localStorage.getItem('dataKey') === null) {
          localStorage.setItem('dataKey', JSON.stringify(data));
      } 
      window.history.go(-1);
    };

    const handleChangeText = (e) => {
      amountRef.text = e.target.value;
    }

    const handleRefChangeText = (e) => {
      refRef.text = e.target.value;
    }

    const handleEmailChangeText = (e) => {
      emailRef.text = e.target.value;
    }

    const uuidv4 = () => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
      .replace(/[xy]/g, function (c) {
          const r = Math.random() * 16 | 0, 
              v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
      });
    };

    const serializedCallback = onNewScanResult.toString();

    const ConfirmModal = () => {
      return (
        <Modal show={showModal} onHide={handleModalClose}>
          <Modal.Body>
              <ModalForm/>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
           Confirm
          </Button>
        </Modal.Footer>
        </Modal>
      );
    };

    const TransactionSuccessModal = () => {
      return (
        <Modal show={showSuccessModal} onHide={handleSuccessModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Transaction Status</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <FontAwesomeIcon icon={faCheckCircle} size="3x" color="green" />
          <p>Payment transaction is successful!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleShowReceipt}>
            Show Receipt
          </Button>
          <Button variant="secondary" onClick={handleSuccessModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      );
    };

    const ReceiptModal = () => {
      return (
        <Modal show={showReceiptModal} onHide={handleReceiptModalClose} 
          dialogClassName="modal-90w" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Transaction Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={receiptUrl} width="100%" height="500px"></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleReceiptModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      );
    };

    const  ModalForm = () => {
      return (
          <Form.Group >
                  <Form.Label>Account Name: </Form.Label>
                  <Form.Control type="text" value={localStorage.getItem('accountInfo') === null ? '' : JSON.parse(localStorage.getItem('accountInfo')).bank_account_holder} readOnly/>
                  <Form.Label>Account No: </Form.Label>
                  <Form.Control type="text" value={localStorage.getItem('accountInfo') === null ? '' : JSON.parse(localStorage.getItem('accountInfo')).bank_account_no} readOnly/>
                  <Form.Label>Bank Name: </Form.Label>
                  <Form.Control type="text" value={localStorage.getItem('accountInfo') === null ? '' : JSON.parse(localStorage.getItem('accountInfo')).bank_name} readOnly/>
                  <Form.Label>Amount (RM): </Form.Label>
                  <Form.Control type="text" _ref={amountRef} onChange={handleChangeText} value={amountRef?.text} placeholder="Enter amount to pay (RM)" input/> 
                  {/* <Form.Label>Reference: </Form.Label>
                  <Form.Control type="text" _ref={refRef} onChange={handleRefChangeText} value={refRef?.text} placeholder="Payment" input/>
                  <Form.Label>E-mail: </Form.Label>
                  <Form.Control type="email" _ref={emailRef} onChange={handleEmailChangeText} value={emailRef?.text} placeholder="test@gmail.com" input/>    */}
              </Form.Group>
      );
  }
    return (
        <>
        <div className="app d-flex flex-column">
          {/* Navigation Header */}
          <div className="navbar-brand">
              <img src="/static/kriptonow.png" className="logo" alt="logo" />
          </div>
  
  
          {/* Footer */}
          
          <Navbar style={{ backgroundColor: '#c78c06' }} className="fixed-bottom">
            <Nav className="w-100 justify-content-around">
                <Nav.Link>{<BsFillGridFill />}</Nav.Link>
                <Nav.Item>
                  <NavLink  to="qrgen" className="nav-link"><BsFillPersonFill /> </NavLink>
                </Nav.Item>
                <Nav.Item onClick={handleModalOpen}>
                  <Link to="/qrscan"
                        state={{ fps: 10, qrbox:250, disableFlip:false, qrCodeSuccessCallback:serializedCallback }} >
                      {<HomeTabIcon />}
                      <IconContext.Provider
                        value={{ size: "2rem", className: "services-icon" }}
                      >
                        <div className="fab">
                          <BsQrCode />
                        </div>
                      </IconContext.Provider>
                    </Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>{<BsFillStarFill />}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>{<RiSettings5Fill />}</Nav.Link>
                </Nav.Item>
            </Nav>
          </Navbar>
        </div>
        <ConfirmModal />
        <TransactionSuccessModal />
        <ReceiptModal />
        </>
        
      );
}

const HomeTabIcon = () => {
    return (
      <div className="home-tab-icon">
        <BsFillHouseFill />
      </div>
    );
  };

  export default Dashboard;