import React, { useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../styles.css";
import { useWeb3Modal } from "@web3modal/react";
import { Container, Box } from "@mui/material";
import { Button } from "react-bootstrap";
import { useAccount } from "wagmi";
import Dashboard from "./Dashboard"

const Connect = () => {

    const { open } = useWeb3Modal();
    const { isConnected } = useAccount();
    const initialized = useRef(false)
    if (!initialized.current) {
        initialized.current = true;
        return !isConnected ? 
        (
            <div className="container-box">
                <Container>
                    <div className="logo">
                        <img src="/static/kriptonow_ori.png" />   
                    </div>
                    <Box sx={{ mt: 2 }}>
                        <div>
                            <Button style={{ backgroundColor: '#c78c06' }} className="btn-lg btn-block mt-5" onClick={ open }>Connect with Polygon (MATIC)</Button>
                        </div>
                    </Box>
                </Container>
            </div>
        ) : ( <Dashboard /> );

    }
    return !isConnected ? 
        (
            <div className="container-box">
                <Container>
                    <div className="logo">
                        <img src="/static/kriptonow_ori.png" />   
                    </div>
                    <Box sx={{ mt: 2 }}>
                        <div>
                            <Button style={{ backgroundColor: '#c78c06' }} className="btn-lg btn-block mt-5" onClick={ open }>Connect with Polygon (MATIC)</Button>
                        </div>
                    </Box>
                </Container>
            </div>
        ) : ( <Dashboard /> );
    
};

export default Connect;