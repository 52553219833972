import React, { Component, useEffect } from "react";
import {
    EthereumClient,
    w3mProvider,
    w3mConnectors,
  } from '@web3modal/ethereum';
import { WagmiConfig, createClient, configureChains } from 'wagmi';
import { Div } from "react-bootstrap";
import { Web3Modal } from '@web3modal/react';
import { polygonMumbai, polygon  } from '@wagmi/chains';
import Connect from "./Connect";

const projectId = '937784eb24b39663c8cae3b0077ad947';
const chains = [ polygon ];
const { provider } = configureChains(chains, [w3mProvider({ projectId })]);

export const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({ version: 1, chains, projectId }),
    provider,
  });

const ethereumClient = new EthereumClient(wagmiClient, chains);


export function WalletConnector() {

  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <Connect />
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
    

}